// src/components/AuthLoginCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setUserInfoToSession } from '../Services/session.mgmt';
import axios from 'axios';
import authService from '../Services/AuthService';
import Unauthorized from '../Pages/Unauthorized/Unauthorized';
import _ from 'lodash';
import { profileService } from '../Services/ProfileService';
import { formatDate, addMonths } from 'date-fns';
import { DATE_FORMAT } from '../constants/RepoReactConstants';

const AuthLoginCallback = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [unAuthorized, setUnAuthorized] = useState(false);
  const currentDate = formatDate(new Date(), DATE_FORMAT);

  function divertUserPath(userInfo) {
    setLoadingUserInfo(false);
    if (userInfo.isAdmin) {
      navigate('/userVerification');
    } else if (userInfo.isExternal && userInfo.hasContractProfile) {
      navigate('/misc');
    } else {
      setUnAuthorized(true);
    }
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_GATEWAY_URL}/auth/userInfo`, {
        withCredentials: true,
      })
      .then((response) => {
        const userInfo = {
          userid: response.data.accessToken.userid,
          countryCd: response.data.accessToken.countryCd,
          sitecode: response.data.accessToken.sitecode,
        };
        authService.isExternalUser(userInfo.sitecode).then((externalUserResp) => {
          if (!!externalUserResp.data) {
            userInfo['isExternal'] = true;
            userInfo.userid = externalUserResp.data.result;
          }
          Promise.all([authService.isAdminUser(userInfo.userid), authService.getUserDetail(userInfo.userid)])
            .then(([adminUserResp, userDetailResp]) => {
              userInfo['isAdmin'] = !!adminUserResp.data;
              userInfo['hasContractProfile'] = userDetailResp?.data.length > 0;
              userInfo['cntrctrNb'] = userDetailResp?.data[0]?.cntrctrNb;
              setUserInfo(userInfo);
              if (!_.isEmpty(userDetailResp?.data)) {
                profileService.getContractorPersonal(userDetailResp.data[0].cntrctrNb).then((contractorDetail) => {
                  if (!_.isEmpty(contractorDetail.data.result.cntrctrData2ApiList)) {
                    userInfo['buzLcnsExpireDate'] =
                      contractorDetail.data.result.cntrctrData2ApiList[0].busLcnsExpirDt || currentDate;
                    userInfo['insuranceExpireDate'] =
                      contractorDetail.data.result.cntrctrData2ApiList[0].insInfoExpirDt || currentDate;
                  }
                });
              } else if (userInfo.isAdmin) {
                userInfo['buzLcnsExpireDate'] = formatDate(addMonths(new Date(), 12), DATE_FORMAT);
                userInfo['insuranceExpireDate'] = formatDate(addMonths(new Date(), 12), DATE_FORMAT);
              }
              if (userInfo.isAdmin || !_.isEmpty(userDetailResp.data)) {
                setUserInfoToSession(userInfo);
                divertUserPath(userInfo);
                setLoadingUserInfo(false);
              } else {
                setLoadingUserInfo(false);
                setUnAuthorized(true);
              }
            })
            .catch((reason) => {
              console.log('AuthLoginCallback.retrieveUserInfo Failed: ' + reason.toString());
            })
            .finally(() => {
              console.log('AuthLoginCallback.retrieveUserInfo Completed with : ' + JSON.stringify(userInfo));
            });
        });
      });
  }, []);

  if (loadingUserInfo) return <>loading...</>;
  if (unAuthorized) return <Unauthorized />;
  return (
    <div>
      <h2>Get User Token from Server...</h2>
    </div>
  );
};

export default AuthLoginCallback;
